import { Toast, Tooltip } from 'bootstrap';
import zxcvbn from 'zxcvbn';

const TomSelect = require( 'tom-select' );


//-------------------------------------------------------------------
// Parse cookies and return them in an object.
//
export const parseCookies = () => {

    const cookies = {};

    if ( document.cookie && document.cookie !== '' ) {
        document.cookie.split( ';' ).forEach( cookie => {
            const match = cookie.trim().match( /(\w+)=(.*)/ );

            if ( match !== undefined )
                cookies[ match[1] ] = decodeURIComponent( match[2] );
        } );
    }

    return cookies;
}


//-------------------------------------------------------------------
// Hide or show a target.
//
export const hideOrShow = ( target, classes ) => {

	if ( classes.add ) {
		if ( ! Array.isArray( classes.add ) )
			target.classList.add( classes.add );
		else
			target.classList.add( ...classes.add );
	}

	if ( classes.remove ) {
		if ( ! Array.isArray( classes.remove ) )
			target.classList.remove( classes.remove );
		else
			target.classList.remove( ...classes.remove );
	}
}


//-------------------------------------------------------------------
// Hide or show a target.
//
export const handleErrorField = ( field, state, message, focus ) => {

	// Wrap in try-catch block to ignore not existent fields.
	try {
		switch ( state ) {
			case 'show':
				field.classList.add( 'error' );
				field.parentNode.querySelector( '.error-icon' ).classList.add( 'show' );
				if ( ! field.parentNode.querySelector( '.error-msg' ) ) {
					field.parentNode.insertAdjacentHTML(
						'beforeend',
						`<label class="error-msg">${message}</label>`
					);
				}
				focus && field.focus();
				break;

			case 'remove':
				field.classList.remove( 'error' );
				field.parentNode.querySelector( '.error-icon' ).classList.remove( 'show' );
				const msg = field.parentNode.querySelector( '.error-msg' );
				field.parentNode.removeChild( msg );
		}
	}

	catch ( TypeError ) {
		return;
	}
}


//------------------------------------------------------------------------------
// Swap classes for select form types.
//
export const initSelectFields = dest => {

	const selectFields = dest.querySelectorAll( 'select' );

	if ( ! selectFields )
		return;

	selectFields.forEach( el => {
		if ( el.classList.contains( 'no-selectize' ) )
			return;

		let wanted = {}

		if ( el.classList.contains( 'multi-select' ) ) {
			wanted = {
				remove_button: {
					title: 'Diesen Eintrag löschen.',
				}
			}
		}

		new TomSelect( el , {
			placeholder: 'Bitte auswählen oder durch Texteingabe suchen.',
			hidePlaceholder: true,
			maxOptions: null,
			selectOnTab: true,
			plugins: wanted,
			onItemAdd: function(){
				this.setTextboxValue( '' );
				this.refreshOptions();
			}
		} );
	} );

}


//------------------------------------------------------------------------------
// Listen on changes on file inputs and display file name in label.
//
export const initFileFields = dest => {

	const fileFields = dest.querySelectorAll( 'input[type=file]' );

	fileFields.forEach( el => {
		const fileLabel = el.parentNode.querySelector( '.file-upload-label' );

		el.onchange = () => { updateFileField( el, fileLabel, el.files[0].name ) };
		el.ondrop = event => { el.files = event.dataTransfer.files };
	} );
}


//------------------------------------------------------------------------------
// Add eventlistener to each delete button.
//
export const initToasts = dest => {

	const buttons = dest.querySelectorAll( '.open-dialog' );
	const toasts = dest.querySelectorAll( '.toast' );

	if ( ! toasts )
		return;

	toasts.forEach( el => new Toast( el ) );

	if ( ! buttons )
		return;

	buttons.forEach( button => {
		// Initialize the current toast.
		const fieldset = button.closest( 'fieldset, .training__status' );

		const toast   = fieldset.querySelector( '.toast' );
		const bsToast = Toast.getInstance( toast );

		button.onclick = () => bsToast.show();
	} )
}


//------------------------------------------------------------------------------
// Create custom event and dispatch on button click.
//
export const initDeleteBtns = form => {

	const buttons  = form.querySelectorAll( '.conf-deletion' );
	if ( ! buttons )
		return;

	buttons.forEach( button => {
		button.onclick = event => {
			event.preventDefault();

			const fieldset = button.closest( 'fieldset' );
			fieldset.querySelector( 'input[type="checkbox"]' ).checked = true;
			fieldset.dispatchEvent( new CustomEvent( 'deletion', { 'bubbles': true } ) );
		};
	} );
}


//------------------------------------------------------------------------------
// Listen on click for edit button in foto overlay and trigger input click.
//
export const initEditBtn = form => {

	const buttons   = form.querySelectorAll( '.edit-foto' );

	buttons.forEach( btn => {
		const target = btn.closest( 'fieldset' ).querySelector( 'input[type=file]' );

		btn.onclick = event => {
			event.preventDefault();
			target.click();
		};
	} );
}


//------------------------------------------------------------------------------
// Prevent default event on click for file upload labels.
//
export const initLabels = form => {

	const imageLabels = form.querySelectorAll( '.is-image' );
	if ( ! imageLabels )
		return;

	imageLabels.forEach( el => {
		el.onclick = event => event.preventDefault();
	} );
}


//------------------------------------------------------------------------------
// Handle the checkboxes in contact form.
//
export const handlePhoneChecks = form => {

	const checkMobile = form.querySelector( 'input[for="mobiltelefon"]' );
	const checkPhone  = form.querySelector( 'input[for="telefon"]' );

	if ( ! checkMobile )
		return;

	checkMobile.onchange = () => {
		if ( checkMobile.checked )
			checkPhone.checked = false;
	};

	checkPhone.onchange = () => {
		if ( checkPhone.checked )
			checkMobile.checked = false;
	};
}


//------------------------------------------------------------------------------
// Initialize bootstrap tooltips.
//
export const initTooltips = form => {

	const tooltips = form.querySelectorAll( '[data-bs-toggle="tooltip"]' );

	tooltips.forEach( el => new Tooltip( el ) )
}


//------------------------------------------------------------------------------
// Initialize password fields and functionality.
//
export const initPWFields = form => {

	const btns = form.querySelectorAll( '.eye-icon' );
	if ( btns.length === 0 )
		return;

	btns.forEach( el => {
		el.onclick = () => {
			let input = el.previousSibling.previousSibling
			input.type = input.type === 'password' ? 'text' : 'password';
		};
	} );

	const target   = form.querySelector( 'input[name=new_pw]' );
	const progress = form.querySelector( '.progress' );
	const genPWBtn = form.querySelector( '#gen-pw' );

	target.oninput = () => setTimeout( () => { handleProgress( target, progress ); }, 50 );
	genPWBtn.onclick = () => generatePW( target, progress );
}


//------------------------------------------------------------------------------
// Measure strength of given input.
//
const handleProgress = ( field, progress ) => {

	const bars     = Array.from( progress.querySelectorAll( '.progress-bar' ) );
	const colors   = ['red', 'orange', 'light-green', 'dark-green'];
	const strength = zxcvbn( field.value );
	const score    = strength['score'];

	// Fill the whole bar for best score.
	if ( score === 4 ) {
		bars.forEach( el => {
			el.style.width = '25%';
			el.className = 'progress-bar dark-green';
		} );

		return;
	}

	let start = bars.slice( 0, score + 1 );
	let end = bars.slice( score + 1 );

	start.forEach( el => {
		el.style.width = '25%';
		el.className = `progress-bar ${colors[ score ]}`;
	} );

	end.forEach( el => {
		el.style.width = '0%';
		el.className = 'progress-bar';
	} );
}


//------------------------------------------------------------------------------
// Display privacy field only, when there is one site checked.
// Set address fields hidden and insert new field to bundle them.
//
export const handlePrivacyFields = form => {

	groupFields( form );

	// Display privacy fields only, when there is one interface selected.
	const checks          = Array.from( form.querySelectorAll( 'input[name="datenschutz_schnittstellen"]' ) );
	const sitesContainer  = checks[0].parentNode.parentNode;
	const fieldsContainer = checks[0].parentElement.parentElement.nextElementSibling;
	let someChecked       = checks.some( el => el.checked );

	sitesContainer.onclick = () => {
		someChecked = checks.some( el => el.checked );

		if ( ! someChecked ) {
			hideOrShow( fieldsContainer, { add: 'fold-in', remove: 'fold-out'} );
			fieldsContainer.querySelectorAll( 'input[type="checkbox"]' ).forEach( el => el.checked = false );
		} else {
			hideOrShow( fieldsContainer, { add: 'fold-out', remove: 'fold-in'} );
		}
	};

	if ( ! someChecked )
		hideOrShow( fieldsContainer, { add: 'fold-in' } );
}


//------------------------------------------------------------------------------
// Group together related privacy fields.
//
const groupFields = form => {

	// Group form check elements by category and clone each element.
	const oldChecks = Array.from( form.querySelectorAll( '.form-check[data-groupme]' ) );

	const addressFields = oldChecks
		.filter( el => el.dataset.groupme === 'address' )
		.map( el => el.cloneNode( true ) );

	const companyFields = oldChecks
		.filter( el => el.dataset.groupme === 'company' )
		.map( el => el.cloneNode( true ) );

	const detailFields  = oldChecks
		.filter( el => el.dataset.groupme === 'details' )
		.map( el => el.cloneNode( true ) );

	// Remove all old form checks from fieldset.
	const fieldset  = oldChecks[0].parentNode;
	oldChecks.forEach( el => fieldset.removeChild( el ) );

	// Get new bundled address form check, which will trigger all related fields to be checked.
	const newGroup  = bundleAddressFields( addressFields, fieldset );

	// Fill the fieldset with the new grouped fields.
	fieldset.insertAdjacentHTML( 'beforeend', '<label class="form-label">Adresse</label>' )
	fieldset.appendChild( newGroup );
	fieldset.insertAdjacentHTML( 'beforeend', '<label class="form-label">Firmendaten</label>' )
	companyFields.forEach( el => fieldset.appendChild( el ) );
	fieldset.insertAdjacentHTML( 'beforeend', '<label class="form-label">Details</label>' )
	detailFields.forEach( el => fieldset.appendChild( el ) );
}


//------------------------------------------------------------------------------
// Group together address fields and return new bundled form check.
//
const bundleAddressFields = fields => {

	// Get all address input fields.
	const inputs = fields.map( el => el.firstElementChild );

	// Setup new checkbox which will replace all other checkboxes.
	const newGroup = document.createElement( 'div' );
	const newInput = document.createElement( 'input' );
	const newLabel = document.createElement( 'label' );

	newGroup.className = 'form-check';
	newInput.className = 'form-check-input';
	newLabel.className = 'form-check-label';

	newInput.id = 'select-address-fields';
	newInput.type = 'checkbox';
	newLabel.for = 'select-address-fields';
	newLabel.innerHTML = 'Wohnort anzeigen<br><small class="text-light-grey">PLZ, Wohnort, Bundesland, Land</small>';

	// Activate new checkbox, if there is one address related checkbox checked.
	inputs.forEach( el => {
		if ( el.checked ) {
			newInput.checked = true;
			return;
		}
	} );

	newGroup.appendChild( newInput );
	newGroup.appendChild( newLabel );

	// Append all address fields to new group.
	inputs.forEach( el => {
		el.hidden = true;
		newGroup.appendChild( el );
	} );

	// Check or uncheck all address fields on change.
	newInput.onchange = () => {
		switch ( newInput.checked ) {
			case true:
				inputs.forEach( el => el.checked = true );
				break;

			case false:
				inputs.forEach( el => el.checked = false );
				break
		}
	}

	return newGroup;
}


//------------------------------------------------------------------------------
// Initialize the CKEditor and remove unnecessary link tabs.
//
export const initCKEditor = () => {
	CKEDITOR.replace( 'id_beschreibung', {
		toolbar: [
			[
				'Undo', 'Redo', '-',
				'Bold', 'Italic', 'Underline', '-',
				'Link', 'Unlink', '-',
				'NumberedList', 'BulletedList'
			]
		],
	} );

	CKEDITOR.on( 'dialogDefinition', function( event ) {
		var dialogName = event.data.name;
		var dialogDefinition = event.data.definition;

		// Remove unnecessary tabs, when link is opened.
		if ( dialogName == 'link' ) {
			dialogDefinition.removeContents( 'target' );
			dialogDefinition.removeContents( 'upload' );
			dialogDefinition.removeContents( 'advanced' );
		}
	} );
}


//------------------------------------------------------------------------------
// Display privacy field only, when there is one site checked.
//
export const handleFormsets = form => {

	const formSets = form.querySelectorAll( '.formset-row' );

	formSets.forEach( formSet => {
		const addBtn     = formSet.querySelector( '.add-entry' );
		const deleteBtns = form.querySelectorAll( '.delete-entry' );

		addBtn.onclick = () => {
			const entries    = formSet.querySelectorAll( '.formset-entry' );
			const currAmount = formSet.childNodes[0];
			const newEntry   = entries[ entries.length - 1 ].cloneNode( true );

			prepareNewEntry( newEntry, currAmount.value );

			formSet.insertBefore( newEntry,addBtn );
			currAmount.value++;
		};

		deleteBtns.forEach( el => {
			el.onclick = setTimeout( () => {
				form.querySelector( 'button[type="submit"]' ).click()
			}, 100 );
		} );
	} );

}


//------------------------------------------------------------------------------
// Set either email or mobilephone to required in wizard.
//
export const toggleRequired = dest => {

	const inputs = Array.from( dest.querySelectorAll( 'input[name=email], input[name=mobiltelefon]' ) );

	const inputListener = event => {
		inputs.filter( el => el !== event.target ).forEach( el => ( el.required = ! event.target.value.length ) );
	};

	inputs.forEach( el => el.oninput = event => { inputListener( event ) } );
}


//------------------------------------------------------------------------------
// Clear an input field and it's label or assign new file names.
//
export const updateFileField = ( fileInput, fileLabel, content ) => {

	fileInput.classList.remove( 'has-file' );

	const oldValue = fileLabel.querySelector( 'a, img, p' );
	const newValue = document.createElement( 'p' );

	newValue.className = 'upload-text';
	newValue.innerHTML = content;

	fileLabel.replaceChild( newValue, oldValue );
}


//------------------------------------------------------------------------------
// Check if current view is mogile.
//
export const isMobile = width => {

	return width <= 768;
}


//------------------------------------------------------------------------------
// Clear all values and update all IDs in the new fieldset.
//
const prepareNewEntry = ( newEntry, amount ) => {

	const fieldsets = newEntry.querySelectorAll( 'fieldset' );

	// Remove the old TomSelect Wrapper.
	fieldsets[0].removeChild( newEntry.querySelector( '.ts-wrapper' ) );

	// Clear and update the select field.
	const select = fieldsets[0].querySelector( 'select' );

	select.className = 'form-control';
	select.id = select.id.replace( /[0-9]/, amount );
	select.name = select.name.replace( /[0-9]/, amount );

	let tsSelect = new TomSelect( select , {
		placeholder: 'Bitte auswählen oder durch Texteingabe suchen.',
		hidePlaceholder: true,
		selectOnTab: true,
	} );

	tsSelect.clear();

	// Clear and update the file field and it's label.
	const fileInput = fieldsets[1].querySelector( 'input[type="file"]' );
	const fileLabel = fieldsets[1].querySelector( '.file-upload-label' );

	fileInput.setAttribute( 'id', fileInput.id.replace( /[0-9]/, amount ) );
	fileInput.setAttribute( 'name', fileInput.id.replace( /^id_/, '' ) );
	fileLabel.setAttribute( 'for', fileInput.id );

	updateFileField( fileInput, fileLabel, 'Datei hochladen' );
	fileInput.onchange = () => updateFileField( fileInput, fileLabel, fileInput.files[0].name );

	// Update the delete entry button
	const deleteCheck = fieldsets[2].querySelector( 'input[type="checkbox"]' );
	deleteCheck.name = deleteCheck.name.replace( /[0-9]/, amount );
	deleteCheck.id = deleteCheck.id.replace( /[0-9]/, amount );

	// Update the ID of delete dialog.
	const toast = newEntry.querySelector( '.dialog__delete' );
	toast.id = toast.id.replace( /[0-9]/, amount );

	// Update styles, if the first entry was copied.
	const firstLabel  = fieldsets[0].querySelector( '.form-label' );

	if ( ! firstLabel )
		return;

	const secondLabel = fieldsets[1].querySelector( '.form-label' );

	fieldsets[0].removeChild( firstLabel );
	fieldsets[1].removeChild( secondLabel );
	newEntry.classList.add( 'mt-3' );
	fileLabel.setAttribute( 'style', '--pos-top: .45rem' );
	newEntry.querySelector( '.upload-icon' ).style = '--pos-top: .6rem';
	fieldsets[2].style = '--pos-top: 0';
}


//------------------------------------------------------------------------------
// Generate a secure and strong password.
//
const generatePW = ( target, progress ) => {

	const bars = progress.querySelectorAll( '.progress-bar' );

	let password = '';
	let pwLength = 21;
	const chars  = "0123456789aäbcdefghijklmnoöpqrstuüvwxyz!@#$%^&*()[]{}AÄBCDEFGHIJKLMNOÖPQRSTUÜVWXYZ";
    const array  = new Uint32Array( chars.length );
    window.crypto.getRandomValues( array );

    for ( let i = 0; i <= pwLength; i++ ) {
        password += chars[array[i] % chars.length];
    }

	target.value = password;
	bars.forEach( el => {
		el.className = 'progress-bar dark-green';
		el.style.width = '25%';
	} );
}
