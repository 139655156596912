import { parseCookies, handleErrorField } from './tools';


//------------------------------------------------------------------------------
// Initialize the login app.
//
const initLogin = () => {

	const form = document.querySelector( 'form' );
	form.addEventListener( 'submit', loginHandler );
}


//------------------------------------------------------------------------------
// Handle user login.
//
const loginHandler = event => {
	
	event.preventDefault();

	const cookies = parseCookies();
	const form    = event.target;

	const fields = [ 'username', 'password' ];
	const data = new FormData( form );

	fetch( form.action, {
		method: form.method,
		body: data,
		headers: {
			'X-CSRFToken': cookies['csrftoken'],
		}
	} )

	.then( response => response.json() )

	.then( result => {
		if ( ! result.error ) {
			window.location.replace( result.redirect_url )
			return;
		}

		const errorFields = document.querySelectorAll( '.error' );

		if ( ! errorFields )
			return;

		errorFields.forEach( el => {
			handleErrorField( el, 'remove' );
		} );

		fields.forEach( key => {
			handleErrorField( form[key], 'show', result.message );
		} );
	} )

	.catch( error => console.log( error ) );
}


document.addEventListener( 'DOMContentLoaded', initLogin );
